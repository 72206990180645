// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-jsx": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\src\\templates\\project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-single-jsx": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\src\\templates\\single.jsx" /* webpackChunkName: "component---src-templates-single-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-publications-index-md": () => import("C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\src\\pages\\publications\\index.md" /* webpackChunkName: "component---src-pages-publications-index-md" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\projects\\new website\\Files from Aiden 23-10-2019\\Bev Hood Website\\Bev Website\\.cache\\data.json")

